// src/components/Navbar.js

import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Menu, X } from 'lucide-react';
import logo from '../Images/pblogosaved.png';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <a href="/" className="flex items-center">
                    <img 
                        src={logo} 
                        alt="Piggyback Logo" 
                        className="h-8 md:h-12 w-auto object-contain" 
                    />
                </a>
                <nav className="hidden md:flex space-x-6">
                    <NavLink to="features" smooth={true} duration={500}>Features</NavLink>
                    <NavLink to="how-it-works" smooth={true} duration={500}>How It Works</NavLink>
                    <NavLink to="testimonials" smooth={true} duration={500}>Testimonials</NavLink>
                    <NavLink to="contact" smooth={true} duration={500}>Contact</NavLink>
                </nav>
                <div className="hidden md:block relative">
                    <button className="bg-ffc0c6 text-black px-6 py-2 rounded-full font-semibold hover:bg-opacity-80 transition duration-300">
                        Download Now
                    </button>
                    <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg hidden group-hover:block">
                        <a href="https://apps.apple.com/us/app/piggyback/id0000000000" className="block px-4 py-2 text-black hover:bg-ffc0c6">iOS</a>
                        <a href="https://play.google.com/store/apps/details?id=com.yourcompany.piggyback" className="block px-4 py-2 text-black hover:bg-ffc0c6">Android</a>
                    </div>
                </div>
                <button 
                    className="md:hidden text-black"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
            </div>

            {isMenuOpen && (
                <div className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div 
                        className={`fixed right-0 top-0 h-full w-[80%] max-w-sm bg-white shadow-2xl transform transition-transform duration-300 ease-in-out ${
                            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
                        }`}
                    >
                        <div className="flex flex-col h-full">
                            <div className="flex justify-end p-4">
                                <button 
                                    onClick={() => setIsMenuOpen(false)}
                                    className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                                >
                                    <X size={24} />
                                </button>
                            </div>
                            
                            <nav className="flex-1 px-6 py-4">
                                <div className="space-y-6">
                                    <NavLink 
                                        to="features" 
                                        smooth={true} 
                                        duration={500} 
                                        onClick={() => setIsMenuOpen(false)}
                                        className="block text-xl font-medium py-2 border-b border-gray-100"
                                    >
                                        Features
                                    </NavLink>
                                    <NavLink 
                                        to="how-it-works" 
                                        smooth={true} 
                                        duration={500} 
                                        onClick={() => setIsMenuOpen(false)}
                                        className="block text-xl font-medium py-2 border-b border-gray-100"
                                    >
                                        How It Works
                                    </NavLink>
                                    <NavLink 
                                        to="testimonials" 
                                        smooth={true} 
                                        duration={500} 
                                        onClick={() => setIsMenuOpen(false)}
                                        className="block text-xl font-medium py-2 border-b border-gray-100"
                                    >
                                        Testimonials
                                    </NavLink>
                                    <NavLink 
                                        to="contact" 
                                        smooth={true} 
                                        duration={500} 
                                        onClick={() => setIsMenuOpen(false)}
                                        className="block text-xl font-medium py-2 border-b border-gray-100"
                                    >
                                        Contact
                                    </NavLink>
                                </div>
                            </nav>

                            <div className="p-6 bg-gray-50">
                                <p className="text-center text-sm text-gray-600 mb-4">Download the app</p>
                                <div className="space-y-3">
                                    <a 
                                        href="https://apps.apple.com/us/app/piggyback/id0000000000" 
                                        onClick={() => setIsMenuOpen(false)}
                                        className="block w-full bg-ffc0c6 text-black text-center px-6 py-3 rounded-xl font-semibold hover:bg-opacity-90 transition duration-200 shadow-sm"
                                    >
                                        Download for iOS
                                    </a>
                                    <a 
                                        href="https://play.google.com/store/apps/details?id=com.yourcompany.piggyback" 
                                        onClick={() => setIsMenuOpen(false)}
                                        className="block w-full bg-ffc0c6 text-black text-center px-6 py-3 rounded-xl font-semibold hover:bg-opacity-90 transition duration-200 shadow-sm"
                                    >
                                        Download for Android
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </header>
    );
};

const NavLink = ({ to, children, ...props }) => (
    <Link to={to} className="text-black hover:text-ffc0c6 transition duration-300 cursor-pointer" {...props}>{children}</Link>
);

export default Navbar;
